import gql from 'graphql-tag';

export const RODADAS_QUERY = gql`

query MyRoundsAsSellerQuery($id: Int, $by_farmer: String, $started: Boolean, $ended: Boolean, $page: Int, $limit: Int){

  MyRoundsAsSellerQuery(id: $id, by_farmer: $by_farmer, started: $started, ended: $ended, page: $page, limit: $limit){
    data{
      id
      description
      offer_starts_on
      offer_ends_on
      order_starts_on
      order_ends_on
      open_to_offer
      open_to_order
      my_offered_products(by_farmer: $by_farmer){
        data{
          available_quantity
          reserved_quantity
          batch
          product{
            product_id
            product_category_id
            local_name
            urlPicture
            picture
          }
        }
      }
      creator{
        id
        profile{
          name
        }
      }
      station_owner{
        id
        is_substation
        coordinator
      }
      me_as_seller(by_farmer: $by_farmer){
        __typename
        ... on Farmer {
          farmer_user_id: id
          contact
          identity_document
          dap
          user {
            id
            email
          }
          farmerProducts {
            product_id
            Product {
              product_id
              comercial_name
              local_name
              urlPicture
              picture
            }
          }
        }
        ... on Station {
          station_id: id
          name
          is_substation
          state
          city
          stationProducts {
            quantity
            Product {
              product_id
              comercial_name
              local_name
              picture
              urlPicture
            }
          }
        }
      }
      status{
        code
        type
        description
      }
    }
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
}
`;

export const SALES_ORDERS_QUERY = gql`

query MySalesOrdersQuery($id: Int, $by_farmer: String){

  MySalesOrdersQuery(id: $id, by_farmer: $by_farmer){
    data{
      id   
      created_at
      commercial_round{
        description
        id
        creator{
          profile{
            name
            address
            address_number
            state
            city
          }
        }
        my_offered_products{
          data{
            batch
          }
        }
      }
      status{
        code
        type
        description
        posible_statuses{
          code
          type
          description
        }
      }
      items{
        id
        quantity
        unit_price
        status{
          code
          type
          description
        }
        product{
          product_id
          local_name
        }
      }
    }
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
}`;