import gql from 'graphql-tag';

export const LOGIN = gql`
mutation($login: String!, $password: String!){
    login(login: $login, password: $password){
      id
      login
      email
      msisdn
      phone
      access_token
      email_verified_at
      msisdn_verified_at
      expires_in
      refresh_token
      profile{
        user_type
        name
        farmer{
          group_offerer
        }  
        station{
          id
          name
        }
      }
    }
}
`;