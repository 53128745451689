import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, Navbar, Nav, Dropdown, Button } from 'react-bootstrap/';

import {  SALES_ORDERS_QUERY  } from '../../services/graphql/querys/rodada';
import {  RODADAS_STATUS_MUTATION  } from '../../services/graphql/mutations/rodada';

import { withApollo } from 'react-apollo';

import LOGO from "../../assets/images/logo.png";

// COMPONENTS
// import Lista from '../../Component/Usuarios/List';
// import Adicionar from '../../Component/Usuarios/Adicionar';
// import Editar from '../../Component/Usuarios/Editar';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});

class Pedidos extends Component {
    constructor(props){
        super(props);
        this.state = {
            pedidos: null,
            filter: '',
            by_farmer: !!this.props.match.params.by_farmer ? this.props.match.params.by_farmer : null,
        }
    }
    reqListener = () => {
		window.innerHTML = this.responseText;
	}
    
    getFile = (pedido, farmer) => {
        let UserAccount = JSON.parse(localStorage.getItem('UserAccount'));
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let file = `https://api.agro.pmovil.net/dtc/sales_order/${pedido}/by_farmer/${farmer}`;

        let headers = new Headers();
        headers.append('Authorization', `Bearer ${UserAccount.access_token}`);

        fetch(file, { headers })
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);

                anchor.href = objectUrl;
                anchor.download = `${pedido}-dtc.pdf`;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
            });
    }
    componentWillMount(){
        console.log("CHEGUEI NO MOUNT")
        let body = {
            by_farmer: this.state.by_farmer,
        }
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        console.log("Params Enviar: ", paramsEnviar);

            this.props.client.query({query: SALES_ORDERS_QUERY, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("Data? ", data)
                    console.log("ERROR: ", errors)
                    if(!!data && !!data.MySalesOrdersQuery && !!data.MySalesOrdersQuery.data){
                        this.setState({pedidos: data.MySalesOrdersQuery.data})
                    }
                } else {
                    console.log("resp query: ",data.MySalesOrdersQuery.data)
                    this.setState({pedidos: data.MySalesOrdersQuery.data})
                }
            })
    }

    submit(id, status, index){
        let body = {
            by_farmer: this.state.by_farmer,
            id: id,
            status_code: status,
        }
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        this.props.client.mutate({
            mutation: RODADAS_STATUS_MUTATION, 
            variables: paramsEnviar, 
            errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("DATA: ", data);
                this.props.client.query({query: SALES_ORDERS_QUERY, errorPolicy: 'all'})
                .then(({data, errors}) => {
                    if(errors){
                        console.log("ERROR: ", errors)
                        alert(errors[0].message)
                    } else {
                        console.log("resp query: ",data.MySalesOrdersQuery.data)
                        this.setState({pedidos: data.MySalesOrdersQuery.data})
                    }
                })
            }
        }) 
    }
    valorPedido = (index_purchase) => {
        console.log("Este pedido: ", this.state.pedidos[index_purchase])
        let orders = this.state.pedidos[index_purchase];
        let valorTotal = 0;
        for(let i=0; i<orders.items.length; i++){
            valorTotal += orders.items[i].quantity*orders.items[i].unit_price;
        }
        return valorTotal.toFixed(2);
    }
    
    render() {
        let UserAccount = JSON.parse(localStorage.getItem('UserAccount'));
        return (
            <div className="page-wrapper">
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                    <Navbar.Brand href="/"><img src={LOGO} alt="Farmer Cliente" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link className="active" href="/pedidos/">Pedidos</Nav.Link>
                            <Nav.Link href={`/ofertas/${this.state.by_farmer}`} >Ofertas</Nav.Link>
                            <Nav.Link href={`/tabela-produtos/${this.state.by_farmer}`} >Tabela Conversão Produtos</Nav.Link>
                            <Nav.Link href="/logout/">Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Table striped hover>
                    <thead>
                        <tr>
                            <td className="align-middle userLogado">
                                Olá {!!UserAccount && !!UserAccount.profile ? UserAccount.profile.name : ''}
                            </td>
                            <td colSpan="2" className="text-right">
                                <Dropdown>
                                    {
                                    this.state.filter === '' ?
                                        <Dropdown.Toggle variant="link" id="dropdown-basic">Todos</Dropdown.Toggle>
                                    : this.state.filter === 'new' ?
                                        <Dropdown.Toggle variant="link" id="dropdown-basic">Novo</Dropdown.Toggle>
                                    : this.state.filter === 'rejected_by_supplier' ?
                                        <Dropdown.Toggle variant="link" id="dropdown-basic">Rejeitado - impossível atender</Dropdown.Toggle>
                                    : this.state.filter === 'sended_to_station' &&
                                        <Dropdown.Toggle variant="link" id="dropdown-basic">Enviado à estação</Dropdown.Toggle>
                                    }
                                    <Dropdown.Menu>
                                            <Dropdown.Item onClick={ () => this.setState({filter: ''}) }>Todos</Dropdown.Item>
                                            <Dropdown.Item onClick={ () => this.setState({filter: 'new'}) }>Novo</Dropdown.Item>
                                            <Dropdown.Item onClick={ () => this.setState({filter: 'rejected_by_supplier'}) }>Rejeitado - impossível atender</Dropdown.Item>
                                            <Dropdown.Item onClick={ () => this.setState({filter: 'sended_to_station'}) }>Enviado à estação</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan="4">
                                PEDIDOS
                            </th>
                        </tr>
                    </thead>
                    {
                        this.state.pedidos && this.state.pedidos.map(
                            (pedido,index) => {
                                if( pedido != null && (this.state.filter == '' || this.state.filter === pedido.status.code) ){
                                    return (
                                        <>
                                            <thead>
                                                <tr>
                                                    <th className="text-left">
                                                        <div style={{ alignItems: "center",display: "flex"}}>
                                                            <div style={{ flex:1 }}>
                                                                <span>Pedido: {pedido.id}</span>
                                                            </div>
                                                            <div style={{ flex:1 }}>
                                                                {/* <Button variant="light" href={`https://api.agro.pmovil.net/dtc/sales_order/${pedido.id}/by_farmer/${this.state.by_farmer}`} target="_blank">
                                                                    DTC
                                                                </Button> */}
                                                                <Button variant="light" onClick={() => this.getFile(pedido.id, this.state.by_farmer)} target="_blank">
                                                                    DTC
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th className="align-middle text-left">
                                                        Valor Total: R$ {this.valorPedido(index)}
                                                    </th>
                                                    <th className="text-right">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="link" id="dropdown-basic">
                                                                {pedido.status.description}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {pedido.status.posible_statuses.map((option,key) => (
                                                                    <Dropdown.Item key={`option-kei-${key}`} onClick={() => this.submit(pedido.id, option.code, index)}>{option.description}</Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className="text-left">Nome Rodada: {pedido.commercial_round.description}</th>
                                                    <th className="text-left">Data: {pedido.created_at}</th>

                                                </tr>
                                                <tr>
                                                    <th className="text-left">Produto</th>
                                                    <th className="text-left">Status</th>
                                                    <th className="text-right">Quantidade</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    pedido.items.map(
                                                        (item, ind) => (
                                                            <tr key={`pedido-key-${ind}`}>
                                                                <td className="text-left">{item.product.local_name}</td>
                                                                <td className="text-left">{item.status.description}</td>
                                                                <td className="text-right">{item.quantity.toFixed(3).replace(".",",")} Kg</td>
                                                            </tr>
                                                        )
                                                    )
                                                }
                                            </tbody>
                                        </>
                                    )
                                }
                            }
                        )
                    }
                </Table>
                
            </div>
        )
    }
}
export default withApollo(withStyles(styles)(Pedidos));