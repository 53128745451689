import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, Navbar, Nav } from 'react-bootstrap/';

import { RODADAS_QUERY } from '../../services/graphql/querys/rodada';
import { LIST_FARMERS_TO_OFFER } from '../../services/graphql/querys/farmer';
import { REMOVE_OFERTAS_RODADA } from '../../services/graphql/mutations/oferta';

import LOGO from "../../assets/images/logo.png";

import { withApollo } from 'react-apollo';

import { Visibility } from '@material-ui/icons';
import {
    Button,
    InputLabel,
    MenuItem,
    FormHelperText,
    FormControl,
    Select,
} from '@material-ui/core';

import { 
    LocalOffer,
    Edit,
    DeleteForever
} from '@material-ui/icons';

// COMPONENTS
// import Lista from '../../Component/Usuarios/List';
// import Adicionar from '../../Component/Usuarios/Adicionar';
// import Editar from '../../Component/Usuarios/Editar';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});//eh nois
class Home extends Component {

    constructor(props){
        super(props);
        let uacc = localStorage.getItem('UserAccount');
        console.log(uacc);
        this.state = {
            rodadas: null,
            by_farmer: JSON.parse(uacc).id,
            dono_grupo: !!uacc.profile && !!uacc.profile.farmer ? uacc.profile.farmer.group_offerer : false,
            farmersGrup: [],
            UserAccount: JSON.parse(uacc),
        }
    }

    componentWillMount(){
        console.log("CHEGUEI NO MOUNT")
        if(this.state.dono_grupo){
            this.buscaFarmersToOffer();
        }
        this.buscaRodadas();
        setInterval( () => { this.buscaRodadas(); }, 120000);
    }

    buscaFarmersToOffer(){
        this.props.client.query({query: LIST_FARMERS_TO_OFFER, errorPolicy: 'all', fetchPolicy:"network-only"})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("LISTA: ",data.MyListFarmersToOfferer)
                this.setState({farmersGrup: data.MyListFarmersToOfferer})
            }
        })
    }

    buscaRodadas(){
        let body = {
            started: true, 
            ended: false, 
            by_farmer: this.state.by_farmer,
            page:1, 
            limit: 100
        }
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        this.props.client.query({query: RODADAS_QUERY, variables: paramsEnviar, errorPolicy: 'all', fetchPolicy:"network-only"})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("resp query: ",data.MyRoundsAsSellerQuery.data)
                this.setState({rodadas: data.MyRoundsAsSellerQuery.data})
            }
        })
    }

    removeOferta  = (id) => {
        console.log("remove oferta")
        let body = {
            commercial_round_id: id,
            by_farmer: this.state.by_farmer,
        }
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        this.props.client.mutate({
            mutation: REMOVE_OFERTAS_RODADA, 
            variables: paramsEnviar, 
            errorPolicy: 'all',
        })
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("DATA: ", data);   
                window.location.href = "/";
            }
        })  
    }

    changeByFarmer(e){

        let body = {
            started: true, 
            ended: false, 
            by_farmer: e.target.value,
            page:1, 
            limit: 100
        }

        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        this.props.client.query({query: RODADAS_QUERY, variables: paramsEnviar, errorPolicy: 'all', fetchPolicy:"network-only"})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("resp query: ",data.MyRoundsAsSellerQuery.data)
                this.setState({rodadas: data.MyRoundsAsSellerQuery.data, by_farmer: e.target.value})
            }
        })
    }

    render() {
        console.log("Estado: ", this.state)
        let UserAccount = JSON.parse(localStorage.getItem('UserAccount'));
        return (
            <div className="page-wrapper">
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                    <Navbar.Brand href="/"><img src={LOGO} alt="Farmer Cliente" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link className="active" href="/">Home</Nav.Link>
                            <Nav.Link href={`/pedidos/${this.state.by_farmer}`} >Pedidos</Nav.Link>
                            <Nav.Link href={`/ofertas/${this.state.by_farmer}`} >Ofertas</Nav.Link>
                            <Nav.Link href={`/tabela-produtos/${this.state.by_farmer}`} >Tabela Conversão Produtos</Nav.Link>
                            <Nav.Link href="/logout/">Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div className="userLogado">
                    Olá {!!UserAccount && !!UserAccount.profile ? UserAccount.profile.name : ''}
                </div>
                {this.state.dono_grupo && this.state.farmersGrup.length > 0 &&
                   <div> 
                        <FormControl >
                            <Select
                                id="selectFarmer"
                                value={this.state.by_farmer}
                                name="selectFarmer"
                                onChange={(e) => this.changeByFarmer(e)}
                            >
                                <MenuItem key="defFarm" name={this.state.UserAccount.profile.name} value={this.state.UserAccount.id}>{this.state.UserAccount.profile.name}</MenuItem>
                                {
                                    this.state.farmersGrup.map((farmer) => (
                                        <MenuItem key={`farm-${farmer.user_id}`} name={farmer.name} value={farmer.user_id}>{farmer.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                   </div>
                }
                <Table striped hover>
                    <thead>
                        <tr>
                            <th colSpan="4">Rodadas Atuais</th>
                        </tr>
                        <tr>
                            <th className="text-left">Rodada</th>
                            <th>Data Final</th>
                            <th>Visualizar</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.rodadas && this.state.rodadas.map((rodada, index) => (
                            rodada.open_to_offer &&
                                <tr key={`rodada-key-${index}`}>
                                    <td className="align-middle text-left">{rodada.description}</td>
                                    <td className="align-middle">{rodada.offer_ends_on}</td>
                                    <td className="align-middle">
                                    {rodada.my_offered_products.data.length > 0 &&
                                        <Button 
                                            ref={`visualizar-${index}`}
                                            //variant="link"
                                            component="a" 
                                            href={`/oferta/view/${rodada.id}/${this.state.by_farmer}`}
                                        ><Visibility /></Button> 
                                    }
                                    </td>
                                    <td className="align-middle col-action">
                                        {rodada.my_offered_products.data.length > 0 ?
                                            <div>
                                                <Button 
                                                    ref={`alterar-${index}`}
                                                    variant="contained" 
                                                    color="primary" 
                                                    disableElevation 
                                                    className={this.props.classes.button} 
                                                    style={stylePage.button}
                                                    component="a" 
                                                    href={`/rodada/alterar/${rodada.id}/${this.state.by_farmer}`}
                                                >
                                                    ALTERAR OFERTA
                                                    <Edit style={stylePage.icon}/>
                                                </Button> 
                                                <Button 
                                                    ref={`remover-${index}`}
                                                    variant="contained" 
                                                    color="primary" 
                                                    disableElevation 
                                                    className={this.props.classes.button} 
                                                    style={stylePage.button}
                                                    onClick={() => this.removeOferta(rodada.id)}
                                                >
                                                    REMOVER OFERTA
                                                    <DeleteForever style={stylePage.icon}/>
                                                </Button> 
                                            </div>
                                            :
                                            <div>
                                                <Button 
                                                    ref={`ofertar-${index}`}
                                                    variant="contained" 
                                                    color="primary" 
                                                    disableElevation 
                                                    className={this.props.classes.button} 
                                                    component="a" 
                                                    href={`/rodada/new/${rodada.id}/${this.state.by_farmer}`}
                                                >
                                                    OFERTAR
                                                    <LocalOffer style={stylePage.icon}/>
                                                </Button>
                                            </div>
                                        }
                                    </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
            
    }
}
const stylePage = {
    icon: {
        marginLeft: "0.5rem",
        fontSize: "1rem"
    },
    button: {
        marginLeft: "0.5rem",
        marginRight: "0.5rem"
    }
}
export default withApollo(withStyles(styles)(Home));