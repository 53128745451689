import React, { Component } from 'react';
import { ApolloProvider } from "react-apollo";
import apolloClient from "./services/apollo";
import 'bootstrap/dist/css/bootstrap.min.css';

import Routes from './routes';

//import logo from './logo.svg';
import './App.css';

class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      display: false
    }
  }
  loginIn = () => { this.setState({display: true}); }
  screenPage = () => { this.setState({display: false}); }
  render(){
    return (
      <ApolloProvider client={apolloClient}>
        {
        this.state.display ?
        <div className="App">
          <Routes loginIn={() => false} screenPage={() => false} />
        </div>
        :
        <div className="App">
          {/* <Header />
          <Sidebar /> */}
          <Routes loginIn={this.loginIn} screenPage={this.screenPage} />
        </div>
        }
      </ApolloProvider>
    );
  }
}

export default App;
