import gql from 'graphql-tag';

export const OFERTA_PRODUTO = gql`
mutation offersProduct($commercial_round_id: Int!, $product_id: Int!, $available_quantity: Float!, $by_farmer: String){
    offersProduct(commercial_round_id: $commercial_round_id, product_id: $product_id, available_quantity: $available_quantity, by_farmer: $by_farmer){
      id
    }
}
`;

export const REMOVE_OFERTAS_RODADA = gql`
mutation deleteAllOfferedProduct($commercial_round_id: Int!, $by_farmer: String){
  deleteAllOfferedProduct(commercial_round_id: $commercial_round_id, by_farmer: $by_farmer){
      id
    }
}
`;