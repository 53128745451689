import gql from 'graphql-tag';

export const FARMER_QUERY = gql`

query FarmerQuery($id: String!){
  FarmerQuery(id: $id){
    id
    productive_unit_id
    group_id
    core_id
    station_id
    contact
    identity_document
    dap
    rural_block_registration
    certificate
    certificate_url
    certificate_expiration
    farmerProducts{
      farmer_product_id
      product_id
      measure_unit_id
      amount_units_for_kilo
      week_production_quantity
      sale_quantity_for_unit
      Product {
        product_id
        product_category_id
        local_name
        comercial_name
        unified_product
        picture
        urlPicture
        productCategory {
          product_category_id
          product_category
        }
      }
    }
  }
}`;

export const LIST_FARMERS_TO_OFFER = gql`
query MyListFarmersToOfferer{
  MyListFarmersToOfferer{
    user_id
    name
    state
    city
    legal_document
  }
}`;