import gql from 'graphql-tag';

export const RODADAS_STATUS_MUTATION = gql`

mutation editSalesOrder($id: Int, $status_code: String, $by_farmer: String){
    editSalesOrder(id: $id, status_code: $status_code, by_farmer: $by_farmer){
    id
    status {
      code
      type
      description
      posible_statuses{
        code
        type
        description
      }
    }
  }
}
`;