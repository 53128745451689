import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, Navbar, Nav } from 'react-bootstrap/';

import { RODADAS_QUERY } from '../../services/graphql/querys/rodada';

import LOGO from "../../assets/images/logo.png";

import { withApollo } from 'react-apollo';

import { Visibility } from '@material-ui/icons';
import {
    Button,
    InputLabel,
    MenuItem,
    FormHelperText,
    FormControl,
    Select,
} from '@material-ui/core';

// COMPONENTS
// import Lista from '../../Component/Usuarios/List';
// import Adicionar from '../../Component/Usuarios/Adicionar';
// import Editar from '../../Component/Usuarios/Editar';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});
class Ofertas extends Component {

    constructor(props){
        super(props);
        this.state = {
            rodadas: null,
            by_farmer: !!this.props.match.params.by_farmer ? this.props.match.params.by_farmer : null,
        }
    }

    componentWillMount(){
        console.log("CHEGUEI NO MOUNT")
        this.buscaRodadas();
    }

    buscaRodadas(){
        let body = {
            by_farmer: this.state.by_farmer,
            page:1, 
            limit: 100
        }
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        this.props.client.query({query: RODADAS_QUERY, variables: paramsEnviar, errorPolicy: 'all', fetchPolicy:"network-only"})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("resp query: ",data.MyRoundsAsSellerQuery.data)
                this.setState({rodadas: data.MyRoundsAsSellerQuery.data})
            }
        })
    }

    render() {
        console.log("Estado: ", this.state)
        let UserAccount = JSON.parse(localStorage.getItem('UserAccount'));
        return (
            <div className="page-wrapper">
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                    <Navbar.Brand href="/"><img src={LOGO} alt="Farmer Cliente" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href={`/pedidos/${this.state.by_farmer}`} >Pedidos</Nav.Link>
                            <Nav.Link className="active" href={`/ofertas/${this.state.by_farmer}`} >Ofertas</Nav.Link>
                            <Nav.Link href={`/tabela-produtos/${this.state.by_farmer}`} >Tabela Conversão Produtos</Nav.Link>
                            <Nav.Link href="/logout/">Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div className="userLogado">
                    Olá {!!UserAccount && !!UserAccount.profile ? UserAccount.profile.name : ''}
                </div>
               
                <Table striped hover>
                    <thead>
                        <tr>
                            <th colSpan="3">Rodadas Ofertadas</th>
                        </tr>
                        <tr>
                            <th>Rodada</th>
                            <th>Data Final</th>
                            <th>Visualizar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.rodadas && this.state.rodadas.map((rodada, index) => (
                            rodada.my_offered_products.data.length > 0 &&
                            <tr key={`rodada-key-${index}`}>
                                <td className="align-middle">{rodada.description}</td>
                                <td className="align-middle">{rodada.offer_ends_on}</td>
                                <td className="align-middle">
                               
                                    <Button 
                                         variant="link"
                                         component="a" 
                                        href={`/oferta/view/${rodada.id}/${this.state.by_farmer}`}
                                    ><Visibility /></Button> 
                                
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
            
    }
}
export default withApollo(withStyles(styles)(Ofertas));