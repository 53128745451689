import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, Navbar } from 'react-bootstrap/';
import {  SALES_ORDERS_QUERY  } from '../../services/graphql/querys/rodada';
import LOGO from "../../assets/images/logo.png";

import { withApollo } from 'react-apollo';

const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    title: {
        fontSize: '0.875rem',
        paddingTop: '12px',
        paddingLeft: '0',
        lineHeight: '1.75'
    }
});

class Relatorio extends Component {
    constructor(props){
        super(props);
        this.state = {
            pedidos: null,
            filter: '',
            pedido_id: !!this.props.match.params.pedido_id ? this.props.match.params.pedido_id : null,
        }
    }
    componentWillMount(){
        console.log("CHEGUEI NO MOUNT")
        let body = {
            id: this.state.pedido_id,
        }
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        console.log("Params Enviar: ", paramsEnviar);

        this.props.client.query({query: SALES_ORDERS_QUERY, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("Data? ", data)
                console.log("ERROR: ", errors)
                if(!!data && !!data.MySalesOrdersQuery && !!data.MySalesOrdersQuery.data){
                    this.setState({pedidos: data.MySalesOrdersQuery.data[0]})
                }
            } else {
                console.log("resp query: ",data.MySalesOrdersQuery.data)
                this.setState({pedidos: data.MySalesOrdersQuery.data[0]})
            }
        })
    }
    render(){
        return (
            <div className="page-wrapper">
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                    <Navbar.Brand href="/"><img src={LOGO} alt="Farmer Cliente" /></Navbar.Brand>
                </Navbar>
                {/* <h1
                    style={{
                        padding: "35vh 5vw",
                        fontSize: "5vw"
                    }}
                >EM DESENVOLVIMENTO</h1> */}
                {/* TELATORIO DTC */}
                <Table>
                    <thead>
                        <tr>
                            <th className="text-left">
                                Agricultor: <span class="text-muted">{this.state.pedidos?.commercial_round?.creator.profile.name}</span>
                            </th>
                            <th className="text-left">CPF/CNPJ:</th>
                            <th className="text-left"></th>
                        </tr>
                        <tr>
                            <th className="text-left">Núcleo:</th>
                            <th className="text-left"></th>
                            <th className="text-left">Grupo:</th>
                            <th className="text-left"></th>
                        </tr>
                        <tr>
                            <th className="text-left">
                                Cidade: <span class="text-muted">{this.state.pedidos?.commercial_round?.creator.profile.city}</span>
                            </th>
                            <th className="text-left">
                                Estado: <span class="text-muted">{this.state.pedidos?.commercial_round?.creator.profile.state}</span>
                            </th>
                        </tr>
                        <tr>
                        <th className="text-left" colSpan={4}>
                            Endereço: <span class="text-muted">{this.state.pedidos?.commercial_round?.creator.profile.address}, {this.state.pedidos?.commercial_round?.creator.profile.address_number}</span>
                        </th>
                        </tr>
                    </thead>
                </Table>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>Produto</th>
                            <th>Origem*</th>
                            <th>Lote</th>
                            <th>Quantidade</th>
                            <th>Preço Un.</th>
                            <th>Preço Final</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            console.log("THIS STATE: ", this.state)
                        }
                        {
                            this.state.pedidos &&
                            this.state.pedidos.items.map(
                                (item, ind) => {
                                    let {batch} = this.state.pedidos.commercial_round.my_offered_products.data[ind]
                                    return (
                                    <tr key={`item-pedido-${ind}`}>
                                        <td>{item.product.local_name}</td>
                                        <td></td>
                                        <td>{batch}</td>
                                        <td>{item.quantity.toFixed(3).replace(".",",")}</td>
                                        <td>{item.unit_price.toFixed(2).replace(".",",")}</td>
                                        <td>{(item.quantity * item.unit_price).toFixed(2).replace(".",",")}</td>
                                    </tr>
                                )}
                            )
                        }
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default withApollo(withStyles(styles)(Relatorio));