import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, Navbar, Nav } from 'react-bootstrap/';

import { RODADAS_QUERY } from '../../services/graphql/querys/rodada';

import { withApollo } from 'react-apollo';

import LOGO from "../../assets/images/logo.png";

// COMPONENTS
// import Lista from '../../Component/Usuarios/List';
// import Adicionar from '../../Component/Usuarios/Adicionar';
// import Editar from '../../Component/Usuarios/Editar';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});
class Oferta extends Component {
    constructor(props){
        super(props);
        this.state = {
            oferta: null,
        }
    }
    componentWillMount(){
        console.log("CHEGUEI NO MOUNT", this.props.match.params)
        let body = {
            id: this.props.match.params.id,
            by_farmer: !!this.props.match.params.by_farmer ? this.props.match.params.by_farmer : null,
            page:1, 
            limit: 100
        }

        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});
        console.log("Params Enviar: ", paramsEnviar)
            this.props.client.query({query: RODADAS_QUERY, variables: paramsEnviar, errorPolicy: 'all'})
            .then(({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors)
                } else {
                    console.log("resp query: ",data)
                    
                    this.setState({oferta: data.MyRoundsAsSellerQuery.data[0].my_offered_products.data, nomeRodada: data.MyRoundsAsSellerQuery.data[0].description})
                }
            })
    }

    
    render() {
        let UserAccount = JSON.parse(localStorage.getItem('UserAccount'));
       return (
            <div className="page-wrapper">
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                    <Navbar.Brand href="/"><img src={LOGO} alt="Farmer Cliente" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link className="active" href="/">Home</Nav.Link>
                            <Nav.Link href={`/pedidos/${this.state.by_farmer}`} >Pedidos</Nav.Link>
                            <Nav.Link href={`/ofertas/${this.state.by_farmer}`} >Ofertas</Nav.Link>
                            <Nav.Link href={`/tabela-produtos/${this.state.by_farmer}`} >Tabela Conversão Produtos</Nav.Link>
                            <Nav.Link href="/logout/">Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div className="userLogado">
                    Olá {!!UserAccount && !!UserAccount.profile ? UserAccount.profile.name : ''}
                </div>
                <Table striped hover>
                    <thead>
                        <tr>
                            <th colSpan="4">
                                OFERTA DA RODADA: {this.state.nomeRodada}
                            </th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th className="align-middle text-left">Produto</th>
                            <th className="align-middle">Nome</th>
                            <th className="align-middle">Lote</th>
                            <th className="align-middle">Quantidade</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.oferta && this.state.oferta.map(
                            (oferta, index) => (
                                <tr key={`oferta-${index}`}>
                                    <td className="align-middle text-left">
                                        <img 
                                        style={{width: "50px",height: "50px"}} 
                                        src={
                                            oferta.product.picture ? 
                                            oferta.product.urlPicture : 
                                            "https://crestana.com.br/img/imagens_produto/20190726_200300_1____01%20PRODUTO-SEM-IMAGEM-1000X1000.JPG"
                                        } 
                                    className={this.props.classes.image} 
                                    alt={oferta.product.local_name} 
                                />
                                    </td>
                                    <td className="align-middle">{oferta.product.local_name}</td>
                                    <td className="align-middle">{oferta.batch && oferta.batch}</td>
                                    <td className="align-middle">{oferta.available_quantity.toFixed(3).replace(".",",")} Kg</td>
                                </tr>
                            )
                        )
                    }
                    </tbody>

                
                </Table>
                
            </div>
        )
            
    }
}
export default withApollo(withStyles(styles)(Oferta));