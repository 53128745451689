import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, Navbar, Nav } from 'react-bootstrap/';

import LOGO from "../../assets/images/logo.png";

import { Visibility } from '@material-ui/icons';
import {
    Button,
    InputLabel,
    MenuItem,
    FormHelperText,
    FormControl,
    Select,
} from '@material-ui/core';

// COMPONENTS
// import Lista from '../../Component/Usuarios/List';
// import Adicionar from '../../Component/Usuarios/Adicionar';
// import Editar from '../../Component/Usuarios/Editar';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    },
});
class TabelaProdutos extends Component {

    constructor(props){
        super(props);
        this.boxRef = React.createRef();
        this.state = {
            by_farmer: !!this.props.match.params.by_farmer ? this.props.match.params.by_farmer : null,
        }
    }
    componentDidMount() {
          this.boxRef.current.scrollIntoView();
    }
    render() {
        return (
            <div className="page-wrapper">
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                    <Navbar.Brand href="/"><img src={LOGO} alt="Farmer Cliente" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href={`/pedidos/${this.state.by_farmer}`} >Pedidos</Nav.Link>
                            <Nav.Link href={`/ofertas/${this.state.by_farmer}`} >Ofertas</Nav.Link>
                            <Nav.Link className="active" href={`/tabela-produtos/${this.state.by_farmer}`} >Tabela Conversão Produtos</Nav.Link>
                            <Nav.Link href="/logout/">Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
               <div id="controlResponse" ref={this.boxRef} style={{display: "flex" }}>
                    <Table striped hover style={{flex: 1}}>
                        <thead>
                            <tr>
                                <th colSpan="2">Tabela de conversão - Estação Orgânica</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Hortaliças</th>
                                <th>100 a 250 gramas</th>
                            </tr>
                            <tr>
                                <th>Couve, espinafre, brócolis e couve flor</th>
                                <th>300 a 500 gramas</th>
                            </tr>
                            <tr>
                                <th>Maços de temperos</th>
                                <th>100 a 150 gramas</th>
                            </tr>
                            <tr>
                                <th>1 cabeça de alho</th>
                                <th>50 a 100 gramas</th>
                            </tr>
                            <tr>
                                <th>1 cebola média</th>
                                <th>100 a 250 gramas</th>
                            </tr>
                            <tr>
                                <th>Legumes grandes (abóbora cabotiã, mandioca)</th>
                                <th>1 a 2 kilos</th>
                            </tr>
                            <tr>
                                <th>Legumes pequenos (abobrinha italiana, tomate)</th>
                                <th>100 a 300 gramas</th>
                            </tr>
                            <tr>
                                <th>Frutas Grandes (Abacate, abacaxi, manga)</th>
                                <th>400 a 1000 gramas</th>
                            </tr>
                            <tr>
                                <th>Frutas Pequenas (Laranja, maça, pinha)</th>
                                <th>200 a 400 gramas</th>
                            </tr>
                            <tr>
                                <th>1 penca de banana</th>
                                <th>1 a 2 kilos</th>
                            </tr>
                            <tr>
                                <th>1 bandeja de morango</th>
                                <th>250 gramas</th>
                            </tr>
                            <tr>
                                <th><strong>*Pesagens aproximadas</strong></th>
                            </tr>                        
                        </tbody>
                    </Table>
                    <div style={{width: "4%", background: "#E3E3E3"}}></div>
                    <Table striped hover style={{flex: 1}}>
                        <thead>
                            <tr>
                                <th colSpan="2">Converta em KG</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr aria-rowspan="0.5">
                                <th>KG</th>
                                <th>1,000</th>
                            </tr>
                            <tr>
                                <th>100 G</th>
                                <th>0,100</th>
                            </tr>
                            <tr>
                                <th>200 G</th>
                                <th>0,200</th>
                            </tr>
                            <tr>
                                <th>300 G</th>
                                <th>0,300</th>
                            </tr>
                            <tr>
                                <th>400 G</th>
                                <th>0,400</th>
                            </tr>
                            <tr>
                                <th>500 G</th>
                                <th>0,500</th>
                            </tr>
                            <tr>
                                <th>600 G</th>
                                <th>0,600</th>
                            </tr>
                            <tr>
                                <th>700 G</th>
                                <th>0,700</th>
                            </tr>
                            
                        </tbody>
                    </Table>
                </div>
            </div>
        )
            
    }
}
export default withStyles(styles)(TabelaProdutos);