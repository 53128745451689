import React, { Fragment } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

// import "./assets/styles/Content.css";

//screens
import Home from "./screens/Pages/Home";
import Pedidos from "./screens/Pages/Pedidos";
import Rodada from "./screens/Pages/Rodada";
import EditarRodada from "./screens/Pages/EditarRodada";
import Login from "./screens/Pages/Login";
import Logout from "./screens/Pages/Logout";
import Oferta from "./screens/Pages/Oferta";
import Ofertas from "./screens/Pages/Ofertas";
import TabelaProdutos from "./screens/Pages/TabelaProdutos";
import Relatorio from "./screens/Pages/Relatorio";



const Routes = (props) => { 
    let UserAccount = localStorage.getItem('UserAccount') ? JSON.parse(localStorage.getItem('UserAccount')) : undefined;
    console.log("UserAccount: ",UserAccount);
  return(
        <BrowserRouter >
            <Fragment>
                <Switch>
                    <Route exact path="/login" render={() => <Login loginIn={props.loginIn} screenPage={props.screenPage}/>} />
                    {!UserAccount &&
                        <Redirect to="/login"/>
                    }
                </Switch>
                {UserAccount &&
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/pedidos" component={Pedidos} />
                        <Route exact path="/pedidos/:by_farmer" component={Pedidos} />
                        <Route exact path="/rodada/:action/:id" component={Rodada} />
                        <Route exact path="/rodada/alterar/:id/:by_farmer" component={EditarRodada} />
                        <Route exact path="/rodada/:action/:id/:by_farmer" component={Rodada} />
                        <Route exact path="/ofertas" component={Ofertas} />
                        <Route exact path="/ofertas/:by_farmer" component={Ofertas} />
                        <Route exact path="/oferta/:action/:id" component={Oferta} />
                        <Route exact path="/oferta/:action/:id/:by_farmer" component={Oferta} />
                        <Route exact path="/tabela-produtos/" component={TabelaProdutos} />
                        <Route exact path="/tabela-produtos/:by_farmer" component={TabelaProdutos} />
                        <Route exact path="/relatorio/:pedido_id" component={Relatorio} />
                        <Route exact path="/logout" render={() => <Logout loginIn={props.loginIn} screenPage={props.screenPage}/>} />
                    </Switch>
                }

            </Fragment>
        </BrowserRouter>
    )
}

export default Routes;
