import React, { Component } from 'react';
//import  { Redirect } from 'react-router-dom'
import {
    Snackbar
} from '@material-ui/core';
import { Form } from 'react-bootstrap/';
import MuiAlert from '@material-ui/lab/Alert';
import { withApollo } from 'react-apollo';
import { LOGIN } from '../../services/graphql/mutations/login';
import { withStyles } from '@material-ui/core/styles';
// import '../../assets/styles/login.css';
import LOGO from "../../assets/images/logo.png"
// COMPONENTS

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    textField: {
        width: '100%'
    }
});
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class Login extends Component {
    constructor(props){
        super(props);    
        this.state = {
            login: '',
            password: '',
            open: false,
            style: 'success',
            mensage: ''
        }
    }
    componentWillMount(){
        this.props.loginIn();
        this.handleClose()
    }
    handleClick = () => {
        this.setState({open: true});
    };
    
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({open: false});
    };
    submitIt = () => {
        this.setState({open: false});
        let body = this.state;
        let paramsEnviar = Object.entries(body).filter(([ k, v ]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {});

        console.log("Enviar Parametros: ", paramsEnviar);

        this.props.client.mutate(
            {
                mutation: LOGIN, 
                variables: paramsEnviar,
                errorPolicy: 'all',
            }
        )
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
                this.setState({
                    style:'error',
                    mensage: 'Login ou senha invalido!'
                })
                this.handleClick();
            } else {
                console.log("DATA: ", data);
                localStorage.setItem('UserAccount', JSON.stringify(data.login));
                localStorage.setItem('user_token', data.login.access_token);
                this.setState({
                    style:'success',
                    mensage: 'Você foi logado com sucesso'
                })
                this.handleClick();
                setTimeout(function () {
                    // if(data.login.profile.user_type == "admin_central"){
                    //     window.location.href = "/usuarios";
                    // } else if(data.login.profile.user_type == "admin_station"){
                    //     window.location.href = "/consumidores";
                    // }
                    window.location.href = "/";
                }, 2000);
                
                // alert("Salvo com sucesso !");
            }
        })
    }
    render() {
        console.log(this.state.open)
        return (
            <div id="contentLogin">
                <div className="boxLogin">
                    <div className="box">
                        <div className="logo">
                            <img src={LOGO} alt="Farmer Cliente" />
                        </div>
                        <div className="title">LOGIN</div>
                        <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
                            <Alert onClose={this.handleClose} severity={this.state.style}>
                            {this.state.mensage}
                            </Alert>
                        </Snackbar>
                        <Form>
                            <Form.Label>Nome de Usuário:</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nome de Usuário"
                                defaultValue={this.state.login}
                                onChange={(e) => this.setState({login: e.target.value})}
                            />
                            <Form.Label>Senha:</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Senha"
                                defaultValue={this.state.password}
                                onChange={(e) => this.setState({password: e.target.value})}
                            />
                        </Form>
                        <div className={`button login ${this.state.login !=='' && this.state.password !== '' && 'active'}`}>
                            <button onClick={() => this.submitIt()}>
                                <span>Login</span> <i className="fa fa-check"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(Login));