import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    TextField,
    FormControlLabel,
    Checkbox,
    Fab,
} from '@material-ui/core';
import { Table, Navbar, Nav } from 'react-bootstrap/';
import LOGO from "../../assets/images/logo.png"

import { Save } from '@material-ui/icons';
import { withApollo } from 'react-apollo';

import { RODADAS_QUERY } from '../../services/graphql/querys/rodada';
import gql from 'graphql-tag';

const styles = theme => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    title: {
        fontSize: '0.875rem',
        paddingTop: '12px',
        paddingLeft: '0',
        lineHeight: '1.75'
    }
});


class EditarRodada extends Component {
    constructor(props){
        super(props);
        this.state = {
            farmer: '',
            offerted: '',
            error: '',
            by_farmer: !!this.props.match.params.by_farmer ? this.props.match.params.by_farmer : null,
        }
    }

    componentWillMount(){ //trocar por rodada query pq vai ter os produtos q posso vender idependente se sou station ou agro
        let paramsEnviar = [];
        paramsEnviar['id'] = this.props.match.params.id;
        paramsEnviar['by_farmer'] = this.props.match.params.by_farmer;
        this.props.client.query({query: RODADAS_QUERY, variables: paramsEnviar, errorPolicy: 'all'})
        .then(({data, errors}) => {
            if(errors){
                console.log("ERROR: ", errors)
            } else {
                console.log("resp query: ",data)
                if(data.MyRoundsAsSellerQuery.data[0].me_as_seller.__typename === 'Station'){
                    let produto_id = {} 
                    // data.MyRoundsAsSellerQuery.data[0].me_as_seller.stationProducts&&
                    // data.MyRoundsAsSellerQuery.data[0].me_as_seller.stationProducts.map(
                    //     produto => this.setState({[produto.Product.product_id]:0})
                    // );
                    this.setState({
                        produtos: data.MyRoundsAsSellerQuery.data[0].me_as_seller.stationProducts, 
                        nomeRodada: data.MyRoundsAsSellerQuery.data[0].description, 
                        produto_id
                    });
                    data.MyRoundsAsSellerQuery.data[0].my_offered_products.data&&
                    data.MyRoundsAsSellerQuery.data[0].my_offered_products.data.map(
                        offerted => {
                            let qtd;
                            if(Number.isInteger(offerted.available_quantity)){
                                qtd = offerted.available_quantity.toFixed(3);
                            } else {
                                qtd = offerted.available_quantity;
                            }
                            //this.setState({ [offerted.product.product_id]: qtd});
                            this.setState(
                                prevState => ({
                                    ...prevState,
                                    [offerted.product.product_id]:qtd,
                                    batch: {
                                        ...prevState.batch,
                                        [offerted.product.product_id]:offerted.batch
                                    }
                                })
                            )
                        }
                    );
                }else{
                    
                    let produto_id = {} 
                    // data.MyRoundsAsSellerQuery.data[0].me_as_seller.farmerProducts&&
                    // data.MyRoundsAsSellerQuery.data[0].me_as_seller.farmerProducts.map(
                    //     produto => this.setState({[produto.Product.product_id]:0})
                    // );
                    this.setState({
                        produtos: data.MyRoundsAsSellerQuery.data[0].me_as_seller.farmerProducts, 
                        nomeRodada: data.MyRoundsAsSellerQuery.data[0].description, 
                        produto_id
                    });
                    data.MyRoundsAsSellerQuery.data[0].my_offered_products.data&&
                    data.MyRoundsAsSellerQuery.data[0].my_offered_products.data.map(
                        offerted => {
                            let qtd;
                            console.log("OFFERTED: ", offerted)
                            if(Number.isInteger(offerted.available_quantity)){
                                qtd = offerted.available_quantity.toFixed(3);
                            } else {
                                qtd = offerted.available_quantity;
                            }
                            //this.setState({ [offerted.product.product_id]: qtd});
                            this.setState(
                                prevState => ({
                                    ...prevState,
                                    [offerted.product.product_id]:qtd,
                                    batch: {
                                        ...prevState.batch,
                                        [offerted.product.product_id]:offerted.batch
                                    }
                                })
                            )
                        }
                    );
                }
            }
        })
    }
    
    submitIt = () => {
        console.log("SubmitIT !!!");
        let prodCheck = document.getElementsByName('ofertaProd');
        // console.log(prodCheck);
        let commercial_round_id = this.props.match.params.id;
        this.setState({error: false});

        let variables = {};
        let types = "";
        let request = "";
        console.log(prodCheck)
        for(let i=0; i<prodCheck.length; i++){
            if(prodCheck[i].checked === true){
                let qtd = document.getElementsByName("qtdProd[" + prodCheck[i].id + "]")[0].value;
                let lote = document.getElementsByName("loteProd[" + prodCheck[i].id + "]")[0].value;
                variables[`product_id_${i}`] = prodCheck[i].id;
                variables[`available_quantity_${i}`] = qtd.replace(/,/i, '.');
                if(lote && lote !== null) { 
                    variables[`batch_${i}`] = lote;
                } else {
                    variables[`batch_${i}`] = "";
                }
                variables[`commercial_round_id_${i}`] = commercial_round_id;
                
                if(this.state.by_farmer){
                    variables[`by_farmer_${i}`] = this.state.by_farmer;
                }

                types = types + `$product_id_${i}: Int!, `
                types = types + `$available_quantity_${i}: Float!, `;
                types = types + `$batch_${i}: String, `;
                types = types + `$commercial_round_id_${i}: Int!, `;

                types = types + `$by_farmer_${i}: String, `;

                request = `${request}
                    product${i}: editOfferedProduct(
                        commercial_round_id: $commercial_round_id_${i}, 
                        product_id: $product_id_${i}, 
                        available_quantity: $available_quantity_${i}, 
                        batch: $batch_${i}, 
                        by_farmer: $by_farmer_${i}
                    ){
                        id
                    }
                `;
                console.log("qtd: ", qtd);
            }
        }
        console.log("variables: ", variables);
        console.log("Request: ", request);
        console.log("Types: ", types);
        this.props.client.mutate({
            mutation: gql`
                mutation editOfferedProduct(${types}){
                    ${request}
                }
            `, 
            variables: variables, 
            errorPolicy: 'all'
        })
        .then(
            ({data, errors}) => {
                if(errors){
                    console.log("ERROR: ", errors);

                } else {
                    console.log("DATA: ", data);    
                    alert("Sua oferta foi alterada com sucesso !!!");
                    window.location.href = "/";  
                }
            }
        )
    }
    peso = (value, id) => {
		let v = value,
			integer = v.split(',')[0];


		v = v.replace(/\D/, "");
		v = v.replace(/^[0]+/, "");

		if(v.length <= 3 || !integer)
		{
            console.log(v)
			if(v.length === 1) v = '0.00' + v;
			if(v.length === 2) v = '0.0' + v;
			if(v.length === 3) v = '0.' + v;
			//if(v.length === 4) v = '0.' + v;
		} else {
            v = v.replace(/^(\d{1,})(\d{3})$/, "$1.$2");
            console.log(parseFloat(v) < parseFloat(this.state.max))
        }
        //return parseFloat(v).toFixed(3);
		this.setState({[id]:v});
	}
    lote = (value, id) => {
        this.setState(
            prevState => ({
                ...prevState,
                batch: {
                    ...prevState.batch,
                    [id]:value
                }
            })
        )
	}
    render(){
        let UserAccount = JSON.parse(localStorage.getItem('UserAccount'));
        console.log("state: ",this.state)
        return(
            <div  key="main">
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                    <Navbar.Brand href="/"><img src={LOGO} alt="Farmer Cliente" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href={`/pedidos/${this.state.by_farmer}`} >Pedidos</Nav.Link>
                            <Nav.Link href={`/ofertas/${this.state.by_farmer}`} >Ofertas</Nav.Link>
                            <Nav.Link href={`/tabela-produtos/${this.state.by_farmer}`} >Tabela Conversão Produtos</Nav.Link>
                            <Nav.Link href="/logout/">Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div className="userLogado">
                    Olá {!!UserAccount && !!UserAccount.profile ? UserAccount.profile.name : ''}
                </div>
            {!!this.state.produtos && !!this.state.produtos.length > 0 ?
                <div key="temProduto">
                    
                    <div></div>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th colSpan="5">
                                    <sub className={this.props.classes.root}>Altere sua ofera de produtos:</sub>
                                    <p>RODADA: {this.state.nomeRodada}</p>
                                </th>
                            </tr>
                            <tr>
                                <th colSpan="2"></th>
                                <th>PRODUTO</th>
                                <th>LOTE</th>
                                <th>QUANTIDADE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.produtos.map((produto, index) => (
                                <tr key={`list-rodade-key-${index}`}>
                                    <td className="align-middle colImgProduct">
                                        <img 
                                            style={{width: "50px",height: "50px"}} 
                                            src={
                                                produto.Product.picture ? 
                                                produto.Product.urlPicture : 
                                                "https://crestana.com.br/img/imagens_produto/20190726_200300_1____01%20PRODUTO-SEM-IMAGEM-1000X1000.JPG"
                                            } 
                                            className={this.props.classes.image} 
                                            alt={produto.Product.local_name} 
                                        />
                                    </td>
                                    <td className="align-middle colImgProduct">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name={`ofertaProd`}
                                                    id={`${produto.Product.product_id}`}
                                                    color="primary"
                                                />
                                            }
                                        />
                                    </td>
                                    <td className="align-middle">
                                        {produto.Product.local_name}
                                    </td>
                                    <td className="align-middle colImput">
                                        <TextField 
                                            name={`loteProd[${produto.Product.product_id}]`} 
                                            className={this.props.classes.textField}
                                            variant="outlined"
                                            margin="dense"
                                            type="text"
                                            value={this.state.batch && this.state.batch[produto.Product.product_id]}
                                            onChange={(e) => { this.lote(e.target.value, produto.Product.product_id) }}
                                        /> 
                                    </td>
                                    <td className="align-middle colImput">
                                        <TextField 
                                            name={`qtdProd[${produto.Product.product_id}]`} 
                                            className={this.props.classes.textField}
                                            variant="outlined"
                                            margin="dense"
                                            type="number"
                                            step={0.1}
                                            value={this.state[produto.Product.product_id]}
                                            onChange={(e) => { this.peso(e.target.value, produto.Product.product_id) }}
                                        /> 
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="4">
                                    <Fab variant="extended" className={this.props.classes.textField} color="primary" onClick={() => this.submitIt()}>
                                        <Save />
                                        Salvar
                                    </Fab>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            :
                <sub className={this.props.classes.root}>SEM PRODUTOS</sub>
            }
            </div>
        );
    }
}
export default withApollo(withStyles(styles)(EditarRodada));